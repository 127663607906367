import { ApolloClient, ApolloQueryResult, NormalizedCacheObject, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { QueryStatus } from '@syconium/magnolia/src/lib/hooks/types'

import { FETCH_PAGE_VIEW } from '../../../../lib/graphql/queries'

import type { PageView } from '../../../../types/graphql'

export type UseFetchPageDataProps = {
  handle: string
  preview: boolean
}

export type PageData = {
  pageView: PageView
}

export const primeClientForFetchPageData = ({
  client,
  props: { handle, preview },
}: {
  client: ApolloClient<NormalizedCacheObject>
  props: UseFetchPageDataProps
}): Promise<ApolloQueryResult<PageData>> => {
  const queryPageDataPromise = client.query<PageData>({
    query: FETCH_PAGE_VIEW,
    variables: { handle, preview },
  })

  return queryPageDataPromise
}

export const usePageData = ({ handle, preview }: UseFetchPageDataProps) => {
  const {
    data: pageDataResponse,
    error: pageError,
    loading: isPageLoading,
    previousData: previousPageData,
    called: pageCalled,
  } = useQuery<PageData>(FETCH_PAGE_VIEW, {
    variables: {
      handle,
      preview,
    },
  })

  const pageData = isPageLoading ? previousPageData : pageDataResponse

  const pageDataStatus = useMemo<QueryStatus>(() => {
    if (isPageLoading) return 'pending'
    if (pageError) 'rejected'
    if (pageCalled || pageData) return 'resolved'
    return 'idle'
  }, [isPageLoading, pageCalled, pageData, pageError])

  return {
    pageData,
    pageDataStatus,
  }
}
