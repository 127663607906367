import React from 'react'

import { useFixturesContext } from '@syconium/magnolia/src/brunswick/containers/fixtures'
import { SeoMeta } from '@syconium/magnolia/src/components/SeoMeta'

export const PageMeta: React.FC<{
  handle: string
  pathname: string
  noindex?: boolean
  title?: string
  socialDescription?: string
  socialImage?: string
}> = ({ handle, noindex, title, socialDescription, socialImage, pathname }) => {
  const {
    globals: { canonicalBaseUrl },
  } = useFixturesContext()

  let canonicalPath = pathname
  if (handle === 'home') canonicalPath = '/'

  const canonicalUrl = `${canonicalBaseUrl.home.slice(0, -1)}${canonicalPath}`

  return (
    <SeoMeta
      canonicalUrl={canonicalUrl}
      noindex={noindex}
      title={title}
      description={socialDescription}
      image={socialImage}
    />
  )
}
