import { GraphQLError } from 'graphql/error'
import { FC, useEffect } from 'react'
import { DefaultTheme, ThemeProvider } from 'styled-components'

import {
  defaultDarkTheme,
  defaultLightTheme,
  olympicDarkTheme,
  olympicLightTheme,
} from '@syconium/little-miss-figgy'
import { useInternationalAvailability } from '@syconium/magnolia/src/lib/hooks/useInternationalAvailability'
import NotFound from '@syconium/magnolia/src/pages/404'

import { reportClientError } from '../../../app/_components/chrome/scripts/DataDogRumScript'
import { useEmailCaptureForm } from '../../../containers/EmailCaptureFormContainer'
import { PageCategory } from '../../../types/signals'
import { BusinessSchemaScript } from '../../chrome/global-head/schemas/businessSchemaContent'
import { OrganizationSchemaScript } from '../../chrome/global-head/schemas/organizationSchemaContent'

import { useTrackNostoPageView } from './hooks/useTrackNostoPageView'
import { useTrackPageView } from './hooks/useTrackPageView'
import { PageMeta } from './PageMeta'
import { Template } from './Template'

import type { PageSection, PageView } from '../../../types/graphql'

const themeForOverrideKey: Record<DefaultTheme['name'] | 'default', DefaultTheme> = {
  dark: defaultDarkTheme,
  light: defaultLightTheme,
  default: defaultLightTheme,
  olympicDark: olympicDarkTheme,
  olympicLight: olympicLightTheme,
} as const

export type PageProps = {
  errors: ReadonlyArray<GraphQLError> | null
  handle: string
  isWrap: boolean
  hasLayout: boolean
  pageView: PageView | null
  pathname: string
  preview: boolean
}

export const Page: FC<PageProps> = ({
  errors,
  handle,
  hasLayout,
  isWrap,
  pageView,
  pathname,
  preview,
}) => {
  const didQueryFail: boolean = Boolean(errors && errors.length > 0) || !pageView
  const { setHideEmailCaptureForm } = useEmailCaptureForm()
  const { isUnavailablePage } = useInternationalAvailability()

  useEffect(() => {
    if (didQueryFail) {
      reportClientError({
        error: errors?.[0],
        context: {
          scope: 'Page',
          handle,
        },
      })
    }
  }, [didQueryFail, errors, handle])

  const pageSections: PageSection[] = pageView?.sections || []

  const { hideEmailCaptureForm, title, socialImage, socialDescription, noindex } = pageView ?? {}

  const themeOverride = pageView?.themeOverride ?? 'default'

  useTrackNostoPageView(handle)
  useTrackPageView({
    handle: handle,
    category: handle === 'home' ? PageCategory.Home : PageCategory.Landing,
  })

  useEffect(() => {
    if (typeof hideEmailCaptureForm === 'boolean') {
      setHideEmailCaptureForm(hideEmailCaptureForm)
    }
  }, [hideEmailCaptureForm, setHideEmailCaptureForm])

  if (isUnavailablePage(handle)) return <NotFound />

  return (
    <ThemeProvider theme={themeForOverrideKey[themeOverride]}>
      <PageMeta
        handle={handle}
        noindex={noindex}
        title={title}
        pathname={pathname}
        socialDescription={socialDescription}
        socialImage={socialImage}
      />
      <OrganizationSchemaScript />
      <BusinessSchemaScript />

      <Template
        didQueryFail={didQueryFail}
        handle={handle}
        hasLayout={hasLayout}
        isWrap={isWrap}
        pageSections={pageSections}
        preview={preview}
      />
    </ThemeProvider>
  )
}
