import Script from 'next/script'
import { FC } from 'react'

const schemaJSON = `
{ "@context": "http://schema.org",
      "@type": "Organization",
      "name": "FIGS",
      "legalName" : "FIGS",
      "logo": "https://cdn.shopify.com/s/files/1/0139/8942/files/FIGS_LOGO_BLACK.jpg?v=1616794989",
      "url": "https://www.wearfigs.com/",
      "foundingDate": "2013",
      "image": "https://cdn.shopify.com/s/files/1/0139/8942/files/FIGS_LOGO_BLACK.jpg?v=1616794989",
      "founders": [
        {
          "@type": "Person",
          "name": "Heather Hasson"
        },
        {
          "@type": "Person",
          "name": "Trina Spear"
        }
      ],
      "sameAs": [
        "http://www.facebook.com/wearfigs",
        "http://www.twitter.com/wearfigs",
        "http://www.instagram.com/wearfigs",
        "http://www.pinterest.com/wearfigs",
        "https://www.youtube.com/channel/UCskE4w90N39VbZVDkiNVnlA/videos"
      ],
      "mainEntityOfPage": [
        "https://www.businessoffashion.com/news/retail/scrubs-company-figs-files-for-ipo",
        "https://www.barrons.com/articles/figs-to-list-its-shares-on-the-nyse-51620332699",
        "https://csq.com/2021/03/how-disrupting-the-medical-scrubs-industry-led-to-this-female-founded-startup-raising-75m-backing/",
        "https://www.forbes.com/sites/maddieberg/2020/04/28/mission-critical-scrubs-maker-figs-steps-up-efforts-to-spiff-up-the-hospital-ward/",
        "https://www.inc.com/tim-crino/figs-heather-hasson-trina-spear.html",
        "https://www.refinery29.com/en-gb/figs-scrubs",
        "https://www.wsj.com/articles/this-company-is-fast-becoming-the-warby-parker-of-scrubs-1539625191",
        "https://en.wikipedia.org/wiki/FIGS_(apparel)"
      ],
      "description": "FIGS is a direct-to-consumer healthcare apparel and lifestyle brand that celebrates, empowers and serves current and future generations of healthcare professionals. FIGS is committed to helping these Awesome Humans look, feel and perform at their best by creating technically advanced apparel and products that feature an unmatched combination of comfort, durability, function and style.",
      "address": {
        "@type": "PostalAddress",
        "addressLocality": "Los Angeles",
        "addressRegion": "CA",
        "addressCountry": "United States"
      },
      "contactPoint": {
        "@type": "ContactPoint",
        "contactType": "customer support",
        "telephone": "424-500-8209",
        "email": "stat@wearfigs.com"
      }
    }
`

export const OrganizationSchemaScript: FC = () => (
  <Script
    type='application/ld+json'
    dangerouslySetInnerHTML={{ __html: schemaJSON }}
    id='org-schema'
  />
)
