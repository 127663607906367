import { GraphQLError } from 'graphql'

import { cachePolicies } from '@syconium/magnolia/src/lib/response-cache'
import { singleQueryParamValue } from '@syconium/magnolia/src/lib/utils'
import { PageView } from '@syconium/magnolia/src/types/graphql'
import { ApolloClientProfile } from '@syconium/weeping-figs'

import { Page } from '../../../components/pages/Page'
import { primeClientForFetchPageData } from '../../../components/pages/Page/hooks/usePageData'

import type {
  MagnoliaPage,
  MagnoliaPageContext,
  MagnoliaPageInitialProps,
} from '../../../components/pages/MagnoliaApp'

export type PageRouteProps = MagnoliaPageInitialProps<{
  errors: ReadonlyArray<GraphQLError>
  handle: string
  pageView: PageView
  pathname: string
  preview: boolean
}>

const PageRoute: MagnoliaPage<PageRouteProps> = props => {
  return (
    <Page
      errors={props.errors}
      handle={props.handle}
      isWrap={false}
      hasLayout={true}
      pageView={props.pageView}
      pathname={props.pathname}
      preview={props.preview}
    />
  )
}

PageRoute.getInitialProps = async function (pageContext: MagnoliaPageContext) {
  const client = pageContext.apolloClientFactory.for(ApolloClientProfile.FigsPublicSupergraph)
  const handle: string = singleQueryParamValue(pageContext.query.handle, 'home')
  const preview: boolean = singleQueryParamValue(pageContext.query.content, '') === 'preview'
  const cacheBypass =
    singleQueryParamValue(pageContext.query.features, '') === process.env.CACHE_BYPASS_FEATURE ||
    preview

  const queryPageDataPromise = primeClientForFetchPageData({ client, props: { handle, preview } })

  const {
    data: { pageView },
    errors: graphQlErrors,
  } = await queryPageDataPromise
  const errors = graphQlErrors ?? []

  return {
    pageView,
    handle,
    pathname: pageContext.asPath ?? `/pages/${handle}`,
    preview,
    errors,
    responseCacheControl:
      cacheBypass || errors.length > 0
        ? cachePolicies.noCache
        : cachePolicies.defaultStorefrontPolicy,
  }
}

export default PageRoute
