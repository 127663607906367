import Script from 'next/script'
import { FC } from 'react'

const schemaJSON = `
{
  "@context" : "http://schema.org",
  "@type" : "LocalBusiness",
  "name" : "FIGS",
  "image" : "https://cdn.shopify.com/s/files/1/0139/8942/files/FIGS_LOGO_BLACK.jpg?v=1616794989",
  "telephone" : "424-500-8209",
  "email" : "stat@wearfigs.com",
  "address": {
    "@type": "PostalAddress",
    "addressLocality": "Los Angeles",
    "addressRegion": "CA",
    "addressCountry": "United States"
  }
}
`

export const BusinessSchemaScript: FC = () => (
  <Script
    type='application/ld+json'
    dangerouslySetInnerHTML={{ __html: schemaJSON }}
    id='business-schema'
  />
)
